.wrp{
    margin-top: 50px;
    background: #F3F4F4;
    padding-top: 5rem;
    padding-bottom: 4rem;
}

.title{
    margin-bottom: 5rem;
    text-align: center;
}

.slide{
    height: auto;
}