.dropdownTitleWrp{
    display: flex;
    align-items: flex-start;

    border-bottom: 1px solid #383c40;

    cursor: pointer;

    user-select: none;

    margin: 7px 15px 0;
}

.dropdownTitleWrp:hover{
    opacity: 0.5;
}

.dropdownTitle{
    padding-bottom: 5px;
    font-size: 0.9rem;
}

.arrow{
    font-size: 18px;
    margin-right: 10px;
    margin-top: 2px;
    transition: all .3s ease;
}

.arrowUp{
    transform: rotateZ(180deg);
}

.arrowDown{
    transform: rotateZ(0);
}