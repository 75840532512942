.wrp {
    padding: 25px 30px;
    margin-bottom: 20px;
    background: #F3F4F4;
}

.title {

}

.desc {
    margin-top: 5px;
    color: #707070;
}

.price {
    font-weight: bold;
}

.imageHeight {
    position: relative;
}

.innerWrp{
    width: 100%;
    padding-top: 100%;
}

.img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;
}