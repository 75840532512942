.wrp{
    padding: 30px;
    background: white;
}

.date{
    color: #b6b6b6;
}

.btnWrp{
    justify-content: flex-start;
}

.dateWrp{
    margin: 15px 0;
}

@media (min-width: 992px) {
    .btnWrp{
        justify-content: flex-end;
    }
}