.wrp{
    height: 100%;
    /*padding-top: 30px;*/
    background: #F3F4F4;
}

.bannerWrp{
    height: 320px;
}

.blogWrp{
    background: white;
    padding: 30px;
    margin-bottom: 100px;
}

.content{
    font-size: 16px;
    margin-top: 20px;
    color: #707070;
}

.content *{
    font-family: inherit !important;
}

.meta{
    font-size: 14px;
    color: #707070;
    margin-bottom: 10px;
}

@media(min-width: 768px){
    .blogWrp{
        background: white;
        padding: 30px 60px 50px;
    }
}