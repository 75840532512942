.wrp{
    padding: 50px 0;
}

.swiperContainer{
    margin-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
}

.slideInner{
    width: 100%;
}

.title{
    color: #000;
}

.swiperSlideCustom{
    width: auto;
}

@media(min-width: 1200px){
    .title{
        bottom: 5rem;
    }
}