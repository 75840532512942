.mobileNavbarSidebarWrp{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.mobileNavbarSidebar{
    width: 270px;
    height: 100%;
    background: white;
}

.sidebarLogo{
    width: 30%;
    display: block;
    margin: auto;
}

.sidebarLogoWrp{
    border-bottom: 1px solid #b6b6b6;
    padding-top: 15px ;
    padding-bottom: 15px ;
}

.navItemMargin{
    margin: 15px 30px 0;
}

.navItem{
    display: block;
    margin: 15px 30px 0;
    padding-bottom: 6px;
    border-bottom: 1px solid #b6b6b6;
    color: #707070;
    transition: color 0.2s ease;
}

.navItem:hover{
    color: #E2CA6E;
}

.sidebarCloseBtn{
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 20px;
    color: #000;
    cursor: pointer;
    transition: opacity 0.3s ease;

    z-index: 3;
}

.sidebarCloseBtn:hover{
    opacity: 0.6;
}

.sidebarDarkOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
}

.LanguageDropdownToggle{
    border: 1px solid black;
    border-radius: 30px;
    padding: 10px 20px;
}