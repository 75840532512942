.checkoutSuccessWrp{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.bgDarkTransparent{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
}

.checkoutSuccessText{
    position: relative;
    padding: 20px 30px;
    font-size: 17px;
    background: white;
    border-radius: 10px;
}

.checkoutSuccessText i {
    color: #E2CA6E;
    font-size: 22px;
}