.wrp{
    background: white;
    padding: 20px 0;
    box-shadow: 0px 0px 20px -4px rgba(0, 0, 0, 0.35);
}

.textItemWrp{
    padding-right: 15px;
    padding-left: 15px;
    color: black;
    margin-bottom: 10px;
}

.textItemWrp:last-child{
    border: none;
}

/*.textItemWrp:last-child{*/
/*    margin: 0;*/
/*}*/

.wrp h6{
    font-size: 1.1rem;
}

.textItemWrp p{
    font-size: 0.9rem;
}

.iconWrp{
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    background: #E2CA6E;

    color: white;

    margin-right: 10px;
}

.iconWrp i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo{
    display: flex;
    justify-content: center;
    width: 120px;
    margin: 0 auto 20px;
}

.logo img {
    width: 50px;
    height: auto;
    object-fit: contain;
}

.socialWrp{
    width: 100%;
    margin-top: 10px;
}

.contactInfo{
    width: 100%;
    text-align: center;
}



@media (min-width: 992px) {
    .wrp{
        padding: 25px 0;
    }

    .logo{
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .logo img{
        width: 70%;
    }

    .contactInfo{
        display: flex;
        width: auto;
    }

    .textItemWrp{
        border-right: 1px solid #b6b6b6;
    }

    .socialWrp{
        width: auto;
    }
}

