.wrp{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.innerWrp{
    width: 100%;
    padding-top: 40%;
    position: relative;
}

.text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: white;
}

@media (min-width: 992px) {
    .innerWrp{
        padding-top: 20%;
    }
}