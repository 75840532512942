.wrp{
    border-bottom: 1px solid #dddede;
    padding: 10px;
    position: relative;
}

.itemTitleWrp{
    padding-right: 10px;
    font-size: 0.95rem;
}

.amount{
    color: #E2CA6E;
}

.itemName{
    display: block;
    color: #383c40;
}
.itemOptions{
    color: #b6b6b6;
    font-size: 0.9rem;
}
.itemPrice{
    font-size: 0.95rem;
}

.itemTitleWrp{
    padding: 10px;
}

@media(min-width: 556px){
    .itemTitleWrp{
        padding: 20px;
    }
}

.imageWrp{
    width: 150px;
    margin: 0 auto;
}

.imageInnerWrp{
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.imageWrp img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    position: absolute;
    top: 0;
    left: 0;
}

@media (min-width: 992px) {
    .imageWrp{
        width: 130px;
    }
}

.removeBtn, .editBtn{
    position: absolute;
    top: 10px;
    width: 35px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 30px;
    box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.5);

    background: white;

    cursor: pointer;

    transition: all .3s ease;
}

.removeBtn:hover, .editBtn:hover{
    background: #e9e9e9;
}

.removeBtn{
    right: 10px;
}
.editBtn{
    left: 10px;
}