.wrp{
    margin-top: 50px;
    margin-bottom: 50px;
    background: #F3F4F4;
    padding-top: 5rem;
    padding-bottom: 4rem;
}

.title{
    margin-bottom: 3.5rem;
    text-align: center;
}

.slide{
    height: auto;
    box-shadow: 0px 0px 20px -4px rgba(0,0,0,0.2);
}

.imgWrp{
    position: relative;
    padding-top: 80%;
}

.imgWrp img {

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
}

.textWrp{
    padding: 25px;
    background: white;
}

.sliderWrp{
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
}