.wrp{
    background: #F3F4F4;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.slideInner{
    width: 100%;
    padding-top: 80%;

    position: relative;
    overflow: hidden;
}

.slideInner::before{
    content: "";
    display: block;

    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
}

.slideInner:hover .slideImg{
    transform: scale(1.08);
}

.slideImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    object-fit: cover;

    transition: all .3s ease;
}

.title{
    position: absolute;
    z-index: 2;

    bottom: 3.5rem;
    right: 2.5rem;

    color: white;

    text-align: right;
}

@media(min-width: 1200px){
    .title{
        bottom: 3rem;
    }
}