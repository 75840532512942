.wrp{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 999;

    overflow: auto;


}

.darkBg{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba(0,0,0,0.5);
}



.popupWrp{
    width: 90%;
    background: white;

    position: absolute;
    left: 50%;
    top: 10px;

    padding-bottom: 85px;
    margin-bottom: 30px;

    box-sizing: content-box;
}

@media(min-width: 550px){
    .popupWrp{
        top: 100px;
        width: 500px;
    }
}

.popupImg{

    height: 90px;
    width: 100%;

    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    align-items: flex-end;

    padding: 20px 30px;

    position: relative;
}

.popupImg::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
}

.bannerTitle{
    color: white;
    position: relative;
}

.closeIcon{
    position: absolute;
    top: 20px;
    right: 30px;

    font-size:20px;

    color: white;
    cursor: pointer;
}

.mainWrp{
    padding: 0px 30px 20px;
    position: relative;
}

.ingItem:first-child{
    padding-top: 10px;
}
.ingItem{
    padding-left: 15px;
    padding-right: 15px;
}




.addToCartBtnWrp{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.addToCartBtninnerWrp{
    width: 100%;
    padding: 15px 40px 20px;
}

.childText{
    font-size: 1.1rem;
    font-weight: bold;
}