.custom{
    border: 2px solid #E2CA6E;
    color: #E2CA6E;
    background: transparent;
}

.custom.active{
    color: white;
}

.custom::before{
    background: #E2CA6E;
}

.custom:hover{
    color: white;
}