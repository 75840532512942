.amntWrp{
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 0 10px;
    user-select: none;
}

.amntBtn{
    width: 30px;
    height: 30px;

    position: relative;

    border: 2px solid #E2CA6E;
    border-radius: 50%;


    cursor: pointer;
}

.amntBtn i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 12px;
}

.amntBtn:hover{
    background: #E2CA6E;
}

.amnt{
    margin: 0 5px;
    font-size: 16px;
}