.wrp{
    padding-top: 100px;
    padding-bottom: 100px;
}

.title{
    margin-bottom: 30px;
}

.title, .par{
    color: #383c40;
    padding: 0 10px;
}

.par{
    font-size: 16px;
}