.wrp{
    margin-bottom: 35px;

}

.imgWrp{
    position: relative;

    width: 100%;
    height: 100%;
    padding-top: 55%;
}

.img{
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    object-fit: cover;
}

.textWrp{
    background: white;
    padding: 35px;
}

.date,
.title,
.text{
    color: #383c40;
}

.date{
    color: #707070;
    margin-bottom: 20px;
}

.title{
    margin-bottom: 10px;
}

.text{
    margin-bottom: 30px;
}

@media(min-width: 768px){
    .normImgCol{
        padding-right: 0;
    }

    .reverseImgCol{
        order: 2;
        padding-left: 0;
    }

    .normTextCol{
        padding-left: 0;
    }

    .reverseTextCol{
        padding-right: 0;
    }
}
