.wrp{
    background: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.name{
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.desc{
    color: #b6b6b6;
}

.imgWrp{
    position: relative;
    width: 100%;
    padding-top: 80%;
}

.img{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
}

.price{

}

.buyWrp{
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #b6b6b6;
}

.buyBtn{
    background: #E2CA6E;
    border-radius: 50%;
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.buyBtn:hover{
    opacity: 0.8;
}

.boxShadow{
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}