.wrp{
    background: #F3F4F4;
    margin: 0px 0 50px;
    padding: 10px 0 50px;
    height: 100%;
}

.searchBtn{
    padding: 5px 20px;
    background: #E2CA6E;
    color: white;

    box-shadow: none;
    border: none;

    font-size: 16px;
}

.searchForm:focus{
    outline: none;
    box-shadow: none;
}

.sortByDesktop, .sortByMobile{
    font-size: 16px;
    padding: 10px;
    border-radius: 0;
    border: 1px solid lightgray;
}

.sortByMobileWrp{
    display: block;
    margin: 30px 0;
}

.sortByDesktopWrp{
    /*display: none;*/
}

.dropdownItem{
    padding: 10px;
    border-top: 1px solid #F3F4F4;
    display: block;
    color: #707070;
}

.dropdownItem:first-child{
    border-top: none;
}

.dropdownItem:hover{
    background: #F3F4F4;
    color: #707070;
}

.pag{
    padding: 5px 9px;
    border: 1px solid #E2CA6E;
    color: #E2CA6E;
}

.pag.active{
    color: #fff;
    background: #E2CA6E;
}

@media (min-width: 992px) {
    .sortByDesktopWrp{
        display: block;
    }

    .sortByMobileWrp{
        display: none;
    }
}