.mobileNavbar{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    padding-top: 8px;
    padding-bottom: 8px;
    background: white;

    z-index: 9;

    -webkit-box-shadow: 0px 9px 14px -4px rgba(0,0,0,0.18);
}

@media (min-width: 1100px) {
    .mobileNavbar{
        display: none;
    }
}

.logoWrp{
    text-align: center;
}

.logo{
    display: block;
    height: 55px;
}

.cartWrp{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
}

.burgerWrp{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
}

.cartWrp, .burgerWrp{
    cursor: pointer;
    transition: opacity .3s ease;
}

.cartIconWrp:hover, .locationIcon:hover, .burgerWrp:hover{
    opacity: 0.6;
}

.cartIcon, .burgerIcon, .locationIcon{
    margin-right: 10px;
    color: #707070;
    font-size: 20px;
}

.cartPrice{
    color: #707070;
}

.cartIconWrp{
    position: relative;
}

.cartAmount{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    top: -10px;
    right: 2px;

    background: #E2CA6E;
    color: white;
}

.locationMessageWrp{
    position: absolute;
    top: 9px;
    right: -9px;

    width: 200px;
    border-radius: 10px;
}

.locationMessageArrow{
    position: absolute;
    top: 0;
    right: 0px;
    font-size: 27px;
    color: #E2CA6E;
    z-index: 2;
}

.locationMessage{
    padding: 10px;
    border-radius: 10px;
    background: #E2CA6E;
    font-size: 1rem;
}

