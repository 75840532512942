.custom{
    background: #ffffff;
    color: #000000;
    border: none;
    box-shadow: 0 0 12px -5px #000;
}

.custom::before{
    background: transparent;
}

.custom:hover{
    background: #dcdcdc;
}