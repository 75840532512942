.btn{
    padding: 20px;
    font-size: 1.3rem;

    border: none;
    background: #383c40;
    color: #fff;

    line-height: normal;

    position: relative;
    transform: perspective(1px) translateZ(0);
    overflow: hidden;

    transition: all .2s ease;
}

.btn::before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: #E2CA6E;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -4;

    transform: translateY(100%);

    transition: all .3s ease-in-out;
}

.btn:hover:before{
    transform: translateY(0);
}

.btn:hover{
    color: white;
}