.wrp{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: white;

    z-index: 9999;
}


@keyframes ldio-qqbyiwqqe2 {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.loader div {
    position: absolute;
    width: 120px;
    height: 120px;
    border: 20px solid #E2CA6E;
    border-top-color: transparent;
    border-radius: 50%;
}
.loader div {
    animation: ldio-qqbyiwqqe2 1s linear infinite;
    top: 100px;
    left: 100px
}
.loadingioSpinnerRolling {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*background: #f1f2f3;*/
}
.loader {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.loader div { box-sizing: content-box; }
/* generated by https://loading.io/ */
