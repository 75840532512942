.wrp:first-child{
    margin-top: 10px;
}

.wrp{
    user-select: none;
    padding: 5px 10px;
}

.fakeCheckbox{
    width: 20px;
    height: 20px;

    border: 1px solid #707070;
    border-radius: 50%;

    margin-right: 5px;

    position: relative;
}

.icon{
    display: none;
    color: #E2CA6E;
    font-size: 12px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checked .icon{
    display: block;
}

.priceModif{
    color: #707070;
}

.text{
    font-size: 0.9rem;
}