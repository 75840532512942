/*geo fonts*/
@font-face {
  font-family: geoBold;
  src: url(assets/fonts/bpg_nino_mtavruli_bold.ttf);
}

@font-face {
  font-family: geoReg;
  src: url(assets/fonts/bpg_arial_2009.ttf);
}

/*eng fonts*/
@font-face {
  font-family: engBold;
  src: url(assets/fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: engReg;
  src: url(assets/fonts/Roboto-Regular.ttf);
}

/*rus fonts*/
@font-face {
  font-family: rusBold;
  src: url(assets/fonts/Intro-Bold.ttf);
}

@font-face {
  font-family: rusReg;
  src: url(assets/fonts/RobotoCondensed-Light.ttf);
}

.btn-border-rounded{
  border-radius: 30px;
}

button:focus{
  outline: none;
}



html{
  font-size: 14px;
}

html,body{
  height: 100%;
}

body{
  padding-top: 71px;
  margin-left: 0;
  margin-right: 0;
  font-family: bpg-arial;
}

@media(min-width: 1100px){
  body{
    padding-top: 0;
  }
}

@media(min-width: 576px){
  body{
    margin-left: 30px;
    margin-right: 30px;
  }
}

a{
  color: inherit;
}

a:hover{
  color: inherit;
  text-decoration: none;
}



h1,h2,h3,h4,h5,h6,p{
  margin-bottom: 0;
}

.h1, h1{
  font-size: 2rem;
}

@media(min-width: 1200px){
  .h2, h2{
    font-size: 2.5rem;
  }

  .h1, h1{
    font-size: 2.7rem;
  }
}

/*noUiSlider*/
.noUi-handle{
  border: 6px solid #E2CA6E;
  border-radius: 50%;

  background: white;
}

.noUi-horizontal .noUi-handle{
  width: 30px;
  height: 30px;
}

.noUi-handle:before, .noUi-handle:after{
  display: none;
}

.noUi-connect{
  background: #E2CA6E;
}

.noUi-horizontal{
 height: 12px;
}

.noUi-horizontal .noUi-handle{
 top: -9px;
}

label{
  margin-bottom: 0;
}

.noUi-connects{
  cursor: pointer;
}

.react-datepicker-wrapper{
  width: 100%;
}

.border-none{
  border: none !important;
}

/*SWIPER*/
.swiper-button-next, .swiper-button-prev{
  color: black;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  margin: 0;
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 20px;
}

.dropdown-item{
  cursor: pointer;
}

.fb_reset{
  position: relative;
}