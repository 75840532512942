.wrp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 999;
}

.overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
}

.modalWrp{
    width: 100%;
    height: 75%;

    min-height: 570px;

    background: #fff;

    margin: auto;

    position: relative;
    z-index: 2;
}

.modalScrollWrp{
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
}

.modal{
    position: relative;
    display: block;
    padding: 20px;
    height: 100%;
}

.mapWrp{

}

.mapWrp > div{
    flex-grow: 2;
}

.closeBtn{
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;

    top: 0;
    right: 0;

    transform: translate(40%, -40%);

    box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.26);

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: all .3s ease;
}

.closeBtn:hover{
    background: #ffe676;
}

@media (min-width: 768px) {
    .modalWrp{
        max-width: 650px;
        height: 80%;
        top: 30px;
    }
}