.bgGray{
    background: #F3F4F4;
}

.swiperContainer{
    height: 100%;
    width: 100%;
    margin: 0;

    position: absolute;
    left: 0;
    top: 0;
}



.sliderWrp{
    position: relative;
    overflow: hidden;

    padding-top: 70%;
}

.swiperSlide{
    height: 100%;
    no-repeat: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
}

.swiperSlide::before{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
}

.slideTextWrp{
    max-width: 700px;
    padding: 20px 30px;
    text-align: center;
    position: relative;
}

.title{
    color: white;
}

.text{
    margin-top: 10px;
    color: white;
    display: none;
}

.slideLink{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: block;
}

@media (min-width: 992px) {
    .text{
        margin-top: 10px;
        color: white;
        display: block;
    }
}

/*@media(min-width: 576px){*/
/*    .swiperContainer{*/
/*        width: calc(540px + (100% - 540px)/2 - 85px);*/
/*    }*/
/*}*/

/*@media(min-width: 768px){*/
/*    .swiperContainer{*/
/*        width: calc(720px + (100% - 720px)/2 - 85px);*/
/*    }*/
/*}*/

/*@media(min-width: 992px){*/
/*    .swiperContainer{*/
/*        width: calc(960px + (100% - 960px)/2 - 85px);*/
/*    }*/

/*    .sliderWrp{*/
/*        height: calc( 100vh - 130px);*/
/*    }*/
/*}*/

/*@media(min-width: 1200px){*/
/*    .swiperContainer{*/
/*        width: calc(1140px + (100% - 1140px)/2 - 85px);*/
/*    }*/
/*}*/

@media(min-width: 992px){
    .sliderWrp{
        height: calc( 100vh - 400px);
        padding: unset;
    }
}