.outerWrp{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.innerWrp{
    max-width: 100%;
    height: 100%;
    background: white;
    margin-left: auto;
}

.innerScroll{
    overflow: auto;
    height: 100%;
    padding-bottom: 100px;
}

@media (min-width: 576px) {
    .innerWrp{
        max-width: 360px;
    }
}

.cartDarkOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
}


.titleWrp{
    background: #F3F4F4;
    padding: 20px;
}

.title{
    color: #383c40;
    margin-bottom: 0;
}

.closeBtn{
    font-size: 20px;
    color: #707070;
    /*color: #707070;*/
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.closeBtn:hover{
    opacity: 0.6;
}





.itemActions i{
    color: #b6b6b6;
    cursor: pointer;
    transition: opacity .3s ease;
}

.itemActions i:last-child{
    margin-left: 15px;
}

.itemActions i:hover{
    opacity: 0.6;
}



.summary{
    padding: 20px;
    font-size: 0.95rem;
}
.summaryLeft{
    color: #b6b6b6;
    text-align: right;
}
.summaryRight{
    color: #383c40;
    font-weight: bold;
    text-align: left;
}

.summaryLeftLg, .summaryRightLg{
    font-size: 1.3rem;
}


.checkoutBtn{
    width: 100%;
    position: absolute !important;
    left: 0;
    bottom: 0;
}



